/* FeaturedSlider Component */
.featured_swiper {
position: relative;
padding: 30px 0;
z-index: 1;
cursor: pointer;

}

.featured_slides {
position: relative;
overflow: hidden;
width: calc((100% - 100px) / 3);
height: 370px;
background-color: #fff !important;
border-radius: 10px;
transition: all 0.3s ease;
border: 1px solid transparent;
  
}

.featured_slides:hover {
transform: scale(1.02);
z-index: 2;
}

.featured_slides.swiper-slide-active, .featured_slides:hover {
transform: scale(1.05);
z-index: 2;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
border: 1px solid rgba(255, 255, 255, 0.564);
}
 .swiper-pagination-bullet-active{
background-color: black;
}
.featured_title {
font-family: "Archivo", sans-serif;
font-size: 16px;
font-weight: 400;
color: #000;
margin-top: 30px;
margin-bottom: 20px;
text-align: center;
}


.featured_img {
width: 100%;
height: 220px;
margin: 0 auto;
background-color: transparent;
display: flex;
justify-content: center;
align-items: center;
}

.featured_img img {
width: 100%;
height: 100%;
object-fit: contain;
background-color: transparent;
transition: all 0.3s ease;
}

.products_price {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 30px;
}

.final_price {
font-family: "Archivo", sans-serif;
font-size: 26px;
font-weight: 900;
color: #000;
margin-right: 5px;
}

.old_price {
font-family: "Archivo", sans-serif;
font-size: 18px;
font-weight: 600;
color: rgb(75, 66, 66);
text-decoration: line-through;
margin-left: 5px;
}
.featured_slides:hover .featured_title,
.featured_slides:hover .final_price,
.featured_slides:hover .old_price {
color: #e30605;
}

@media screen and (max-width: 1199px) {
.featured_slides {
width: calc((100% - 50px) / 2);
margin-right: 50px;
}
}

@media screen and (max-width: 992px) {
.featured_slides {
width: calc((100% - 30px) / 2);
margin-right: 20px;
}
}

@media screen and (max-width: 767px) {
.featured_slides {
   width: 45vw;
    margin-right: 1px;
    height: auto;

}
}

@media screen and (max-width: 575px) {
.featured_swiper {
  padding: 55px 0;
  z-index: 1;
}

.featured_slides {
 width: 50vw;
  height: fit-content;
  margin-right: 1px;
}
.featured_slides:hover {
  transform: scale(1.1);
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.products_price {
   display: flex;
  justify-content: center;
  align-items: center;
margin-bottom: 20px;
}



/* Set font size based on screen size */
@media screen and (max-width: 575px) {
.featured_title {
font-size: 18px;
  font-weight: 400;
   margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
}
.featured_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: transparent;
  transition: all 0.3s ease;
}


.final_price {
  font-size: 1.8rem;
  font-weight: bold;
    margin-right: 5px;
}

.old_price {
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: line-through;
  margin-left: 10px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
.featured_title {
font-size: 18px;
}

.final_price {
font-size: 1.8rem;
font-weight: bold;
}

.old_price {
font-size: 1.3rem;
font-weight: bold;
margin-left: 10px;
}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
.featured_title {
font-size: 28px;
}

.final_price {
font-size: 1.8rem;
font-weight: bold;
}

.old_price {
font-size: 1.4rem;
font-weight: bold;
margin-left: 10px;
}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
.featured_title {
font-size: 32px;
}

.final_price {
font-size: 1.8rem;
font-weight: bold;
}

.old_price {
font-size: 1.6rem;
font-weight: bold;
margin-left: 10px;
}
}

@media screen and (min-width: 1200px) {
.featured_title {
font-size: 36px;
}

.final_price {
font-size: 2rem;
font-weight: bold;
}

.old_price {
font-size: 1.8rem;
font-weight: bold;
margin-left: 10px;
}
}



/* Show active product with left and right border in slider */
.swiper-slide {
opacity: 0.6;
transition: opacity 0.5s ease;
background-color: transparent;
border: none;
}

.swiper-slide-active {
opacity: 1;
border-left: 5px solid #e30605;
border-right: 5px solid #e30605;
}

/* Set transparent background for swiper dots */
.swiper-pagination {
background-color: transparent;
}

/* Set color for active swiper dot button */
.swiper-pagination-bullet-active {
background-color: black;
}

}
}

.featured_swiper .swiper-slide-next,
.featured_swiper .swiper-slide-prev {
  margin-right: 10px;
}

.featured_swiper .swiper-slide-next + .swiper-slide,
.featured_swiper .swiper-slide-prev + .swiper-slide {
  margin-left: 10px;
}
