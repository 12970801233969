@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root {
  height: 100%;
  width: 100%;
}

body{
  background-color: #f1f1f1;
  margin: 0px;
  padding: 0px;

  font-family: 'Archivo', sans-serif;;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

/* input[type="number"] {
  
  -moz-appearance: textfield;
} */

.greenColor {
  color: green !important; 
}
.redColor { 
  color: red !important;
}
