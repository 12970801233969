
.prodcutDetialsContainer
{
  overflow: hidden !important;
  width: 100%;
}
.product_container {
  margin-top: 7.5rem;
 width: 98vw;
    height: fit-content;
 margin-right: auto; 
    overflow: hidden !important;
    font-family: "Roboto";
      background: #f5f5f5;
    
}


.prod_details_wrapper {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: space-around;
 height: 100%;
    width: 100%;
    align-items: flex-start;
  


}


.prod_details_left_col {
    color: #403e3e;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
padding-left: 1rem;
display: flex;
    width: 50%;
    height: 634px;
    gap: 12px;
    margin-right: 1rem;
        background-color: white;
        margin-left: 10px;
}

.prod_details_tabs {
  padding-bottom: 1rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 0.5rem;
  height: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: baseline;
  width: 5rem;
  padding: 0 1.5rem 0 0; 
  background-color: white; 
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15),
    0 0 0 2px rgba(0, 0, 0, 0.1); 
}

.prod_details_tabs::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.prod_details_tabs::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 2px; /* Rounded corners of the scrollbar thumb */
}

.prod_details_tabs::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

.prod_details_tabs::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 2px; /* Rounded corners of the scrollbar track */
}

.prod_details_tabs::-webkit-scrollbar-track:hover {
  background-color: #ddd; /* Color of the scrollbar track on hover */
}


.prod_details_tabs .tabs_item.active {
    border-color: rgba(190, 190, 190, 0.805);
}

.prod_details_tabs .tabs_item {
    border: 2px solid;
    border-radius: 4px;
    cursor: pointer;
    height: 3rem;
       margin-top: 1em;
    padding: 0.3rem;
    width: -moz-fit-content;
    width: 4rem;

       box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.prod_details_tabs .tabs_item img {
  
   height: 100%;
    object-fit: cover;
    width: 20rem;
    border-radius: 4px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
   
}

img, svg {
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
}

img {
    height: auto;
    max-width: 100%;
}



.prod_details_img img {
     height: 80vmin;
    object-fit: cover;
     max-width: 100%;
    margin-top: 1rem; 
       padding: 10px;
       border-radius: 10px;
  
}
.prod_details_right_col_001{
    width: 45%;
    margin-left: 1rem;
    padding: 1rem 3rem;
    background-color: white;
}

.prod_details_info {
   font-weight: 600;
    color: #524f4ff0;
}
.price_box
{
    margin-right: 3rem;
}


.prod_details_ratings {
    align-items: center;
    display: flex;
    gap: 0.5rem;
}

.rating_star {
    color: #161515 !important;
    color: var(--main-color-2);
    font-size: 1.5rem;
}

.prod_details_ratings a {
    opacity: 1;
}

a {
    color: inherit;
    text-decoration: none;
}


.separator {
    border-top: 1px solid hsla(0,0%,40%,.6);
}
.prod_details_price {
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
    color: #2d2c2c;
}

.prod_details_price .del_price {
    font-size: 1rem;
  color: #626262;
}


del {
   color: #403e3e;
    color: var(--text-muted);
}

.prod_details_price .price {
    font-size: 1.5rem;
    
}

.prod_details_price .saved_price {
    color: green;
    font-weight: 500;
    margin-bottom: 0.4rem;
    font-size: 14px;
}

.prod_details_price .tax_txt {
    color: #403e3e;
    color: var(--text-muted);
    font-size: .9rem;
}




   
.prod_details_price .badge .instock{
 border: 1px solid #008000;
    font-weight: 400;
     background-color: #00800040;
    color: #008000;

    font-size: 12px;
    padding: 5px;
    font-weight: 600;
    border-radius: 5px;
}
.prod_details_price .badge .outofstock{
 background-color: #ff000094;
    color: white;
    border: 1px solid;
    font-size: 14px;
    padding: 5px;
    font-weight: 400;
     border-radius: 5px;
}
.css-i4bv87-MuiSvgIcon-root{
    font-size: 14px !important;
    padding: 5px !important;
    
}

.prod_details_offers h4 {
    font-weight: 600;
  color :#292929
 
}


.prod_details_offers ul {
    display: flex;
    gap: 0.8rem;
    margin-top: 1rem;
}
ul {
    list-style-type: none;
        margin-left: -10px;
        width: 100%;
}




.prod_details_offers ul li {
   

    color: rgb(37, 37, 37);
    border-radius: 3px;
 
    font-size: 14px;
    padding: .8rem;
  font-weight: 400;
 width: fit-content;
      border-radius: 8px;
    background: transparent;
    border-color: rgb(37, 37, 37);
    border: 1px solid;
    

}
.prod_details_offers ul li:hover{
    border:  1.5px solid black;
     
}

.prod_details_addtocart_btn {
      border-style: 2px solid !important;
    cursor: pointer !important;
    line-height: inherit;
    padding: 0px 16px;
    position: relative;
    text-decoration: none;
    text-transform: none;
   
    white-space: nowrap;
    width: 55%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: inherit;
    min-height: 48px;
       background: rgb(22, 22, 22) !important;
    border-color: rgb(19, 19, 19) !important;
    color: rgb(255, 255, 255) !important;

}


.prod_details_addtocart_btn:hover{
     background: rgba(222, 9, 9, 0.744) !important;; 
  transform: scale(1.05) !important;
 border: none !important;
     transition: transform 0.3s ease 0s !important;
     
}
button {
    cursor: pointer;
}

.prod_details_additem {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.additem {
  display: flex;
  align-items: center;
}

.additem_decrease {
  margin-right: 8px;
  height: 24px;
  font-size: 14px;
  background-color: #161616;
  border-color: #1a1a1a;
  border-radius: 3px;
  color: white;
}

.additem_increase {
  margin-left: 8px;
  height: 24px;
  font-size: 14px;
  background-color: #161616;
  border-color: #1a1a1a;
  border-radius: 3px;
  color: white;
}

.additem_increase:hover {
  background-color: #252525;
  border-color: #252525;
  transition: transform 0.3s ease 0s;
  border-style: 2px solid;
}

.additem_decrease:hover {
  background-color: #343434;
  border-color: #494949;
  transition: transform 0.3s ease 0s;
  border-style: 2px solid;
}

input[type="number"] {
  width: 32px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.productDescription{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 80%;
    
    
}
.prod_details_additem h5{
   font-size: 16px;
    font-weight: 600;
    
}

.productDiscriptiopn_text h4{
font-size: 16px;
margin-left: 2px;
margin-bottom: 0px;
padding: 0px;
  color :#292929

}
.productDiscriptiopn_text {
        color: #626262;
          font-size: 14px;
              font-family: "Roboto";
}
.seprator2{
    background-color: #6f6868;
    width: 100%;
    height: .5px;
    margin-top: 1rem;
}

.deliveryText{
    margin-top: 1rem;
    font-size: 16px;
    line-height: 19px;
    font-family: "DIN W01 Regular";
   color :#292929;
      font-weight: 600;
}
.deliveryText>svg{
  width: 1.4rem;
  height: 1.4rem;

}
.reviewCard {
  overflow-x: hidden ;
}

@media (min-width: 1200px) {
  .prod_details_img img {
    height: 75vmin;
  }
}
@media (max-width: 1200px) {
   prod_details_left_col {
 
    height: 700px;
    
}
  .prod_details_offers ul {
    display: flex;
    gap: 0.8rem;
    margin-top: 1rem;
    flex-direction: column;
    margin-left: -35px;
}

}

@media (max-width: 999px) {
  .prod_details_wrapper{
    flex-direction: column;
    gap: 1rem;
  }
 
 .prod_details_left_col {
  
    width: 94.2vw;
    
    height: 70vmax;
    margin: 0 auto;
    justify-content: space-around;
}

.prod_details_right_col_001{
    width: 92vw;
    padding: 1rem;
    height: auto;
    margin: 0 auto;
    justify-content: space-around;
}
.prod_details_tabs{
    width: 5rem;
    height: 40vmax;

    margin-top: 2.5rem;
}

.prod_details_tabs .tabs_item {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.prod_details_offers ul{
    flex-direction: row;
    font-size: 8px;
}
.prod_details_price{
    justify-content: flex-start;
}
.price_box {
        margin: 0px;
}
.productDiscriptiopn_text{
    width: 100%;
}
.prod_details_offers ul li{
    font-size: 8px;
    padding: .5rem;
}
}